import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../layout/dark-default"
import styled from "styled-components"

const SNotFound = styled.section`
  padding-top: 20%;
  background: #333;
  height: 100vh;
  width: 100%;
`
const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 | Glastone Mosaic</title>
      </Helmet>
      <Layout>
        <SNotFound className="wrapper">
          <h3 className="text-center mt-100">Page Not Found</h3>
        </SNotFound>
      </Layout>
    </>
  )
}

export default NotFound
